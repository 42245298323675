div.ant-dropdown.ethnicity-filter-overlay, 
div.ant-dropdown.age-filter-overlay, 
div.ant-dropdown.location-filter-overlay,
div.ant-dropdown.gender-filter-overlay,
div.ant-dropdown.union-filter-overlay {
  /* used in on the search pages; has to assume some fixed position in order to scroll */
  overflow-y: auto;
  max-height: calc(100vh - 209px);
}

div.ant-tooltip.show-hide-tooltip.ant-tooltip-placement-top div.ant-tooltip-arrow {
  bottom: 1px;
}